import { NewsletterSignUpBlockParagraph } from '@/__generated__/graphql';
import { getLanguage } from '@/lib/config';
import { buttonNews, colors, mediaQueries } from '@/theme/lib';
import { ReactElement } from 'react';
import styled, { useTheme } from 'styled-components';

const StyledBlock = styled.div.withConfig({
  shouldForwardProp: (prop) => prop !== "hasSupportingText"
})<{ hasSupportingText: boolean }>`
  box-sizing: border-box;
  width: 100%;
  max-width: 1528px;
  background-color: ${(props) => props.theme.colors.newsletter.bg};
  min-height: 320px;
  position: relative;
  color: ${colors.white};
  overflow: hidden;

  div {
    background-color: transparent;
  }

  .wrapper-block {
    padding: 4.5rem 0.888888889rem;
    width: 100%;
    max-width: 904px;
    position: relative;

    &::after {
      background-image: url(/Newsletter/newsletter-circle.svg);
      background-size: 110% 138%;
      background-repeat: no-repeat;
      background-position: 35px -180px;
      content: "";
      display: ${(props) => props.theme.key === 'zsl' ? 'block' : 'none'};
      position: absolute;
      width: 150%;
      height: 150%;
      top: 0px;
      max-width: 600px;
      left: 25px;
      z-index: 1;
      ${mediaQueries.smHero} {
        background-size: ${(props) => props.hasSupportingText ? undefined : '125% 160%'};
        background-position: ${(props) => props.hasSupportingText ? undefined : '0 0'};
        top: ${(props) => props.hasSupportingText ? undefined : '-98px'};
        left: ${(props) => props.hasSupportingText ? undefined : '0'};
        transform: ${(props) => props.hasSupportingText ? undefined : 'rotate(-90deg)'};
      }
      ${mediaQueries.md} {
        background-size: ${(props) => props.hasSupportingText ? '125% 140%' : '125% 160%'};
        background-position: 0 0;
        top: ${(props) => props.hasSupportingText ? '-138px' : '-118px'};
        left: 0;
        transform: rotate(-90deg);
      }
    }
  }

  .wrapper-content-block {
    background-color: ${(props) => props.theme.key === 'zsl' ? colors.seagrass : 'transparent'};
    position: relative;
    padding: 1rem 0;
    z-index: 2;
  }

  .title-block {
    font-size: 1.5rem;
    line-height: 1.65rem;
    font-weight: 400;
  }

  .supporting-block {
    font-size: 1rem;
    line-height: 1.4rem;
    margin-top: 0.75rem;
    width: 87%;
  }

  .cta-block {
    margin-top: 0.666666667rem;
  }

  .btn-news {
    ${buttonNews}
    line-height: 1.2rem;
    font-weight: 400;

    &:hover,
    &:focus {
      text-decoration: none;
    }

    ${mediaQueries.md} {
      line-height: 1.35rem;
    }
  }

  ${mediaQueries.md} {
    .wrapper-block {
      padding-top: 4.625rem;
      margin: 0 auto;
    }

    .title-block {
      font-size: 2.25rem;
      line-height: 2.475rem;
    }

    .supporting-block {
      font-size: 1.125rem;
      line-height: 1.63125rem;
    }
  }
`;

const Wrapper = styled.div`
  position: relative;
  overflow: hidden;
  display: flex;
  justify-content: center;
  background-color: ${(props) => props.theme.colors.newsletter.bg};
  margin-bottom: 3rem;

  ${mediaQueries.lg} {
    margin-bottom: 4rem;
  }
`;

const Pattern = styled.div`
  display: ${(props) => props.theme.key === 'zsl' ? 'none' : 'block'};
  position: absolute;
  left: auto;

  &:first-child {
    z-index: 1;
    top: -330px;
    left: -150px;

    ${mediaQueries.lg} {
      left: -250px;
      top: 40px;
      width: 410px;
      height: 410px;
    }

    ${mediaQueries.xl} {
      left: -100px;
    }
  }

  &:last-child {
    left: auto;
    bottom: -70%;
    right: -20%;

    ${mediaQueries.lg} {
      width: 470px;
      height: 470px;
      top: -60px;
      right: -110px;
    }
  }

  img.pattern {
    display: block;
    width: 100%;
    height: 100%;

    filter: grayscale(1) invert(1) opacity(0.35);
    ${mediaQueries.lg} {
      filter: none;
    }
  }
`;

export default function NewsletterSignUpBlock({title, supportingText, callToAction}: NewsletterSignUpBlockParagraph): ReactElement {
  const { key } = useTheme();
  const language = getLanguage();
  return <Wrapper>
    <Pattern>
      {key === 'london' && <img loading="lazy" alt="" className="pattern" src="/PatternTextureB.svg" />}
      {key === 'whipsnade' && <img loading="lazy" alt="" className="pattern" src="/PatternTextureD.svg" />}
    </Pattern>
    <StyledBlock hasSupportingText={supportingText !== null}>
      <div className="wrapper-block">
        <div className="wrapper-content-block">
          <div className="title-block" lang={language !== 'en' ? language : undefined}>{title}</div>
          {supportingText && <div className="supporting-block" dangerouslySetInnerHTML={{ __html: supportingText }} lang={language !== 'en' ? language : undefined} />}
          <div className="cta-block">
            <a href={callToAction.url} target={callToAction.target ? callToAction.target : undefined} className="btn btn-news" lang={language !== 'en' ? language : undefined}>
              {callToAction.title}
            </a>
          </div>
        </div>
      </div>
    </StyledBlock>
    <Pattern>
      {key === 'london' && <img loading="lazy" alt="" className="pattern" src="/PatternTextureB.svg" />}
      {key === 'whipsnade' && <img loading="lazy" alt="" className="pattern" src="/PatternTextureD.svg" />}
    </Pattern>
  </Wrapper>
}
